import React, { Component } from "react";
import { ControlLabel, DropdownButton, FormControl, FormGroup, ListGroup, ListGroupItem, MenuItem, PageHeader } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";

import { API } from "aws-amplify";
import * as _ from "lodash";

import "./MergeValueTrees.css"

export default class MergeValuetrees extends Component {
    constructor(props) {
        super(props);
        this.state = {
            valuetrees: [],
            isMerging: false,
            sourceTree: null,
            treeName: "",
            publish: false,
            selectedTrees: []
        }
    }

    async componentDidMount() {
        try {
            const valuetrees = await this.valuetrees();

            this.setState({ valuetrees: _.sortBy(valuetrees, 'treeName') });
        } catch (e) {
            alert(e);
        }
    }

    handleChange = event => {
        this.setState({
          [event.target.id]: event.target.value
        });
    }

    valuetrees() {
        return API.get("valuetrees", "/valuetrees");
    }

    validateForm() {
        return this.state.sourceTree && this.state.selectedTrees.length > 0 && this.state.treeName.length > 0;
    }

    selectSourceTree(tree) {
        var selectedTrees = _.without(this.state.selectedTrees, tree);
        this.setState({
            sourceTree: tree,
            selectedTrees
        });
    }

    clickOnTreeList(tree) {
        if (this.state.sourceTree === tree) {
            return;
        }
        var selectedTrees = this.state.selectedTrees;
        if (selectedTrees.indexOf(tree) === -1) {
            selectedTrees.push(tree);
        } else {
            selectedTrees = _.without(selectedTrees, tree);
        }
        this.setState({ selectedTrees });
    }

    treeListClass(tree) {
        if (this.state.sourceTree === tree) {
            return 'source-tree';
        } else if (this.state.selectedTrees.indexOf(tree) !== -1) {
            return 'selected';
        } else {
            return 'not-selected';
        }
    }

    doMerge() {
        this.setState({ isMerging: true });
        this.startMerge();
    }

    async startMerge() {
        try {
            console.log('------- source data');
            const original = await this.getValuetree(this.state.sourceTree.valuetreeId);
            console.log('original', original);

            const others = await Promise.all(this.state.selectedTrees.map(async valuetree => {
                return await this.getValuetree(valuetree.valuetreeId);
            }));
            console.log('others', others);

            console.log('------- merged tree');
            const merged = {
                content: {
                    links: _.cloneDeep(original.content.links),
                    nodes: _.cloneDeep(original.content.nodes)
                },
                treeName: this.state.treeName,
                publish: this.state.publish
            };

            // Merge other trees one by one
            _.each(others, otherTree => {
                /**
                 * Merge otherTree => merged follows the following steps
                 *  1. Identify new nodes 
                 *  2. Add new nodes to merged and keep track of index
                 *  3. Ignore links whose source and target were both in the original "merged"
                 *  4. Add new links transcribing indexes
                 **/

                // 1.
                const nodes = _.map(otherTree.content.nodes, (node, i) => {
                    var mergedNodeIdx = _.findIndex(merged.content.nodes, n => node.name === n.name);
                    return {
                        node: node,
                        otherNodeIdx: i,
                        mergedNodeIdx: mergedNodeIdx !== -1 ? mergedNodeIdx : null,
                        existedPreviously: mergedNodeIdx !== -1
                    };
                });

                // 2.
                _.each(nodes, node => {
                    if (node.existedPreviously) return;

                    node.mergedNodeIdx = merged.content.nodes.length;
                    merged.content.nodes.push(node.node);
                });

                var otherNodeToMergedIdx = {};
                _.each(nodes, node => {
                    otherNodeToMergedIdx[node.otherNodeIdx] = node.mergedNodeIdx;
                });

                _.each(otherTree.content.links, link => {
                    var originalSource = _.find(nodes, node => node.otherNodeIdx === link.source);
                    var originalTarget = _.find(nodes, node => node.otherNodeIdx === link.target);

                    // 3. do not merge links that are between two previously existing nodes
                    if (originalSource.existedPreviously && originalTarget.existedPreviously) {
                        return;
                    }

                    // 4.
                    var newLink = _.cloneDeep(link);
                    newLink.source = otherNodeToMergedIdx[link.source];
                    newLink.target = otherNodeToMergedIdx[link.target];

                    merged.content.links.push(newLink);
                });

            });

            console.log(merged);
            
            console.log('-------- saving merged result');
            
            const newTree = await this.createValuetree(merged);
            console.log(' Created new tree => ', newTree);
            this.props.history.push("/");

        } catch(e) {
            alert(e);
            this.setState({ isMerging: false });
        }
    }

    createValuetree(valuetree) {
        return API.post("valuetrees", "/valuetrees", {
          body: valuetree
        });
    }

    getValuetree(id) {
        return API.get("valuetrees", `/valuetrees/${id}`);
    }

    render() {
        const selectSourceTree = this.selectSourceTree.bind(this);
        const clickOnTreeList = this.clickOnTreeList.bind(this);
        const treeListClass = this.treeListClass.bind(this);
        return (
        <div className="MergeValuetrees">
            <PageHeader>Merge value trees</PageHeader>

            <FormGroup controlId="treeName">
               <ControlLabel>Name for new merged tree</ControlLabel>
               <FormControl
                  onChange={this.handleChange}
                  value={this.state.treeName}
                  componentClass="input"
                />
            </FormGroup>

               
            <FormGroup controlId="sourceTree">
               <ControlLabel>Select source tree</ControlLabel>
                <p>Links and nodes are retained from the source tree as is.</p>
                <DropdownButton bsSize="large" title={this.state.sourceTree ? this.state.sourceTree.treeName : '-- Select Source Tree --'} id="mergevaluetrees-sourceTree" >
                       {this.state.valuetrees.map(item => {
                          return <MenuItem onSelect={() => selectSourceTree(item) } key={item.valuetreeId}>
                              {item.treeName}
                          </MenuItem>
                       })}
                </DropdownButton>
            </FormGroup>

            <FormGroup controlId="selectedTrees">
                <ControlLabel>Select nodes to merge</ControlLabel>
                <p>Nodes and links that are not in the source tree are added into the merged tree. Nodes are
                   merged according to their exact name. Only links that point to at least one new node in each
                   individual tree will be merged .</p>

                
                <ListGroup id="valuetree-list">
                    {this.state.valuetrees.map(valuetree =>  
                      <ListGroupItem
                          key={valuetree.valuetreeId}
                          onClick={() => clickOnTreeList(valuetree)}
                          className={treeListClass(valuetree)} >
                        {valuetree.treeName}
                      </ListGroupItem>
                    )}
                </ListGroup>
            </FormGroup>

            <LoaderButton
                bsStyle="primary"
                bsSize="large"
                disabled={!this.validateForm()}
                onClick={this.doMerge.bind(this)}
                isLoading={this.state.isMerging}
                text="Merge trees"
                loadingText="Merging…"
              />
        </div>
        );
    }
}