import React, { Component } from "react";
import SankeyChart from "../components/SankeyChart";
import { API } from "aws-amplify";

import "./View.css";

export default class View extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: { nodes: [], links: [] },
      treeName: ''
    };
  }

  async componentDidMount() {
    try {
      const valuetree = await this.getValuetree();
      const { content, treeName } = valuetree;

      this.setState({
        content,
        treeName
      });
    } catch (e) {
      alert(e);
    }
  }

  getValuetree() {
    return API.get("valuetrees", `/valuetrees/${this.props.match.params.id}`);
  }

  nop() { }

  render() {
    return (
      <div className="View">
        <h2>{this.state.treeName}</h2>
        <SankeyChart
              className="the-chart"
              nodes={this.state.content.nodes}
              links={this.state.content.links}
              hiddenNodes={[]}
              highlightedNodeIndeces={[]}
              highlightedLinkIndeces={[]}
              hiddenDepths={[]}
              editNode={this.nop}
              editLink={this.nop}
              rightClickNode={this.nop}
              fitHeight
            />
      </div>
    );
  }


}