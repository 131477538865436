import React, { Component } from "react";
import { PageHeader, ListGroup, ListGroupItem } from "react-bootstrap";
import { Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import "./Home.css";

import * as _ from "lodash";

import { API } from "aws-amplify";

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      notes: []
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }

    try {
      const valuetrees = await this.valuetrees();
      //valuetrees = ;
      this.setState({ valuetrees: _.sortBy(valuetrees, 'treeName') });
    } catch (e) {
      alert(e);
    }

    this.setState({ isLoading: false });
  }

  valuetrees() {
    return API.get("valuetrees", "/valuetrees");
  }

  renderValuetreeList(valuetrees) {
    return [{}].concat(valuetrees).map(
      (valuetree, i) =>
        i !== 0
          ? <LinkContainer
              key={valuetree.valuetreeId}
              to={`/valuetree/${valuetree.valuetreeId}`}
            >
              <ListGroupItem header={valuetree.treeName}>
                {[
                  "Created: " + new Date(valuetree.createdAt).toLocaleString(),
                  "Updated: " + new Date(valuetree.updatedAt).toLocaleString(),
                  "Published: " + (valuetree.publish ? "yes" : "no")
                ].join(', ')}
              </ListGroupItem>
            </LinkContainer>
          : <LinkContainer
              key="new"
              to="/valuetree/new"
            >
              <ListGroupItem>
                <h4>
                  <b>{"\uFF0B"}</b> Create a new value tree
                </h4>
              </ListGroupItem>
            </LinkContainer>
    );
  }

  renderLander() {
    return (
      <div className="lander">
        <h1>Arctic Value Tree</h1>
        <p>An application for documenting Arctic Value Trees</p>
        <Link to="/login" className="btn btn-info btn-lg">
          Login
        </Link>
      </div>
    );
  }

  renderValuetrees() {
    return (
      <div className="valuetrees">
        <PageHeader>Value trees</PageHeader>
        <ListGroup>
          {!this.state.isLoading && this.renderValuetreeList(this.state.valuetrees)}
        </ListGroup>

        <ListGroup>
          <LinkContainer
              key="merge"
              to="/valuetree/merge"
            >
              <ListGroupItem>
                <h4>
                  <b>{"\u21A3"}</b> Merge value trees
                </h4>
              </ListGroupItem>
            </LinkContainer>
        </ListGroup>
      </div>
    );
  }

  render() {
    return (
      <div className="Home">
        {this.props.isAuthenticated ? this.renderValuetrees() : this.renderLander()}
      </div>
    );
  }
}