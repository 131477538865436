import React from "react";
import { Route, Switch } from "react-router-dom";

import AppliedRoute from "./components/AppliedRoute";

import Home from "./containers/Home";
import NewValuetree from "./containers/NewValuetree";
import MergeValueTrees from "./containers/MergeValueTrees";
import Valuetree from "./containers/Valuetree";
import View from "./containers/View";
import Login from "./containers/Login";

import NotFound from "./containers/NotFound";

export default ({ childProps }) =>
  <Switch>
    <AppliedRoute path="/" exact component={Home} props={childProps} />
    <AppliedRoute path="/login" exact component={Login} props={childProps} />
    <AppliedRoute path="/valuetree/new" exact component={NewValuetree} props={childProps} />
    <AppliedRoute path="/valuetree/merge" exact component={MergeValueTrees} props={childProps} />
    <AppliedRoute path="/valuetree/:id" exact component={Valuetree} props={childProps} />
    <AppliedRoute path="/view/:id" exact component={View} props={childProps} />
    { /* Finally, catch all unmatched routes */ }
    <Route component={NotFound} />
  </Switch>;