import React, { Component } from "react";
import { Button, ControlLabel, FormGroup, FormControl } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { API } from "aws-amplify";
import * as _ from "lodash";

import "./NewValuetree.css"

const defaultContent = require('./NewValueTree.default.json');

export default class NewValuetree extends Component {
  constructor(props) {
    super(props);

    this.file = null;

    this.state = {
      isLoading: null,
      copyContentFrom: "default",
      treeName: "",
      publish: false,
      otherValuetrees: []
    };
  }

 async componentDidMount() {
  try {
      const valuetrees = await this.valuetrees();
      //valuetrees = ;
      this.setState({ otherValuetrees: _.sortBy(valuetrees, 'treeName') });
    } catch (e) {
      alert(e);
    }

    this.setState({ isLoading: false });
  }

  valuetrees() {
    return API.get("valuetrees", "/valuetrees");
  }

  validateForm() {
    return !this.state.isLoading && this.state.copyContentFrom && this.state.treeName.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      var content;
      if (this.state.copyContentFrom === 'default') {
        content = defaultContent;
      } else {
        content = (await this.getValuetree(this.state.copyContentFrom)).content;
      }
      await this.createValuetree({
        content: content,
        treeName: this.state.treeName,
        publish: this.state.publish
      });
      this.props.history.push("/");
    } catch (e) {
      alert(e);
      this.setState({ isLoading: false });
    }
  }

  getValuetree(id) {
    return API.get("valuetrees", `/valuetrees/${id}`);
  }

  createValuetree(valuetree) {
    return API.post("valuetrees", "/valuetrees", {
      body: valuetree
    });
  }

  cancel = event => {
      this.props.history.push("/");
  }

  render() {
    return (
      <div className="NewValuetree">
        <form onSubmit={this.handleSubmit}>
          <FormGroup controlId="treeName">
           <ControlLabel>Tree name</ControlLabel>
            <FormControl
              onChange={this.handleChange}
              value={this.state.treeName}
              componentClass="input"
            />
          </FormGroup>
          <FormGroup controlId="copyContentFrom">
            <ControlLabel>Copy value tree from</ControlLabel>
             <FormControl
                  onChange={this.handleChange}
                  value={this.state.copyContentFrom}
                  componentClass="select"
                  placeholder="Select">
                  <option key="default" value="default">select</option>
                  {this.state.otherValuetrees.map((v) => {
                      return <option key={v.valuetreeId} value={v.valuetreeId}>{v.treeName}</option>;
                  })}
            </FormControl>
          </FormGroup>
          <LoaderButton
            bsStyle="primary"
            bsSize="large"
            disabled={!this.validateForm()}
            type="submit"
            isLoading={this.state.isLoading}
            text="Create"
            loadingText="Creating…"
          />
          <Button bsStyle="info" bsSize="large" onClick={this.cancel}>Cancel</Button>
        </form>
      </div>
    );
  }
}