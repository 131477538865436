export default {
  apiGateway: {
    REGION: "eu-west-1",
    URL: "https://fr0d428t3g.execute-api.eu-west-1.amazonaws.com/dev"
  },
  cognito: {
    REGION: "eu-west-1",
    USER_POOL_ID: "eu-west-1_aUdq3P9VL",
    APP_CLIENT_ID: "5b2jh3b3d43q3c3vs55gvq0149",
    IDENTITY_POOL_ID: "eu-west-1:913581f7-3e60-4da6-80f7-ff3ac30c1d63"
  }
};