import React, { Component } from "react";
import { Button, ControlLabel, FormGroup, FormControl, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import LoaderButton from "../components/LoaderButton";
import SankeyChart from "../components/SankeyChart";
import { CirclePicker } from 'react-color';
import Draggable from 'react-draggable';

import { API } from "aws-amplify";

import * as jsFileDownload from 'js-file-download';

import * as _ from "lodash";

import "./Valuetree.css";

const modalStyle = {
  content: {
    top: '75px',
    left: '37%',
    right: 'auto',
    bottom: 'auto',
    border: '0px solid #333',
    width: '450px',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0 , 0.35)'
  },
  colorPicker: {
    width: '400px'
  }
};

const defaultColors =
  [ "#f44336", "#ffeb3b", "#e91e63", "#009688", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3", "#03a9f4",
    "#00bcd4", "#4caf50", "#8bc34a", "#cddc39", "#ffc107", "#ff9800", "#ff5722", "#795548", "#607d8b" ];

class Valuetree extends Component {
  constructor(props) {
    super(props);

    this.file = null;

    this.state = {
      isLoading: null,
      isDeleting: null,
      valuetree: null,
      content: { nodes: [], links: [] },
      hiddenNodes: [],
      lastEditNodeIndeces: [],
      lastEditLinkIndeces: [],
      hiddenDepths: [],
      treeName: "",
      publish: null,
      fullScreenMode: false,
      modalIsOpen: false,
      modalMode: "",

      // Parameters for adding a new node
      modalNewNodeName: "",
      modalNewNodeDescription: "",

      // Parameters for adding a new link
      modalNewLinkSource: null,
      modalNewLinkTarget: null,
      modalNewLinkValue: 1,
      modalNewLinkDescription: "",
      modalNewLinkColor: "",

      // Parameters when editing a node
      modalEditNodeIdx: null,
      modalEditNodeName: "",
      modalEditNodeDescription: "",

      // Parameters when editing a link
      modalEditLinkIdx: null,
      modalEditLinkValue: 0,
      modalEditLinkDescription: "",
      modalEditLinkColor: ""
    };

    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({modalIsOpen: true});
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
    //this.subtitle.style.color = '#f00';
  }

  closeModal() {
    this.setState({ modalIsOpen: false, modalMode: '' });
  }

  async componentDidMount() {
    try {
      const valuetree = await this.getValuetree();
      const { content, treeName, publish } = valuetree;

      this.setState({
        valuetree,
        content,
        treeName,
        publish
      });
    } catch (e) {
      alert(e);
    }
  }

  validateForm() {
    return this.state.treeName.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });
    try {
      await this.saveValuetree({
        content: this.state.content,
        treeName: this.state.treeName,
        publish: this.state.publish
      });
      this.props.history.push("/");
    } catch (e) {
      alert(e);
      this.setState({ isLoading: false });
    }
  };

  saveValuetree(valuetree) {
    return API.put("valuetrees", `/valuetrees/${this.props.match.params.id}`, {
      body: valuetree
    });
  }

  handleDelete = async event => {
    event.preventDefault();

    const confirmed = window.confirm(
      "Are you sure you want to delete this value tree?"
    );

    if (!confirmed) {
      return;
    }

    this.setState({ isDeleting: true });

    try {
      await this.deleteValuetree();
      this.props.history.push("/");
    } catch (e) {
      alert(e);
      this.setState({ isDeleting: false });
    }
  };

  deleteValuetree() {
    return API.del("valuetrees", `/valuetrees/${this.props.match.params.id}`);
  }

  getValuetree() {
    return API.get("valuetrees", `/valuetrees/${this.props.match.params.id}`);
  }

  prepareDownloadValuetree() {
    var svg = document.getElementsByTagName('svg')[0];

    //get svg source.
    var serializer = new XMLSerializer();
    var source = serializer.serializeToString(svg);

    //add name spaces.
    if(!source.match(/^<svg[^>]+xmlns="http:\/\/www\.w3\.org\/2000\/svg"/)) {
        source = source.replace(/^<svg/, '<svg xmlns="http://www.w3.org/2000/svg"');
    }
    if(!source.match(/^<svg[^>]+"http:\/\/www\.w3\.org\/1999\/xlink"/)) {
        source = source.replace(/^<svg/, '<svg xmlns:xlink="http://www.w3.org/1999/xlink"');
    }

    //add xml declaration
    source = '<?xml version="1.0" standalone="no"?>\r\n' + source;

    jsFileDownload(source, `${this.state.treeName}.svg`);
  }

  addNode = () => {
    this.setState({ modalMode: 'addNode', modalNewNodeName: '', modalNewNodeDescription: '' });
    this.openModal();
  };

  addNodeProper = () => {
    let content = this.state.content;
    content.nodes.push({ name: this.state.modalNewNodeName, description: this.state.modalNewNodeDescription || "" });
    this.setState({
        content,
        lastEditNodeIndeces: [ content.nodes.length - 1 ]
    });
    this.closeModal();
  };


  getSubtreeLinks = (nodeIdx) => {
      let outboundLinks = this.state.content.links.filter(link => {
        return link.source === nodeIdx;
      });
      if (_.isEmpty(outboundLinks)) {
        return outboundLinks;
      }
      let retval = outboundLinks.slice();
      outboundLinks.forEach(link => {
        retval = retval.concat(this.getSubtreeLinks(link.target))
      });
      return retval;
  };

  invertSubTreeLinks = () => {
      const idx = this.state.modalEditNodeIdx;
      let linksToInvert = this.getSubtreeLinks(idx);
      linksToInvert.forEach(link => {
        const origSource = link.source;
        link.source = link.target;
        link.target = origSource;
      });
      this.setState(); // state already updated in-place
      this.closeModal();
  };

  canInvertSubTree = () => {
      const idx = this.state.modalEditNodeIdx;
      //Do not allow any inbound links:
      let ok = _.isEmpty(this.state.content.links.filter(link => link.target === idx ));
      if (ok) {
          //require at least some outbound links:
          ok = !_.isEmpty(this.state.content.links.filter(link => link.source === idx ));
      }
      return ok;
  };

  resetHightlight = () => {
      this.setState({
          lastEditLinkIndeces: [],
          lastEditNodeIndeces: []
      });
  };

  addLink = () => {
    this.setState({
      modalMode: 'addLink',
      modalNewLinkSource: 'select',
      modalNewLinkTarget: 'select',
      modalNewLinkValue: 1,
      modalNewLinkDescription: '',
      modalNewLinkColor: '#8bc34a',

   });
    this.resetHightlight();
    this.openModal();
  };

  addLinkProper = () => {
    const content = this.state.content;
    content.links.push({
      source: Number(this.state.modalNewLinkSource),
      target: Number(this.state.modalNewLinkTarget),
      value: Number(this.state.modalNewLinkValue),
      description: this.state.modalNewLinkDescription || "",
      color: this.state.modalNewLinkColor
    });
    const editedLinks = [ content.links.length - 1 ];

    this.setState({
        content,
        lastEditLinkIndeces: editedLinks
    });
    this.closeModal();
  };

  editNode = event => {
    this.setState({
      modalMode: 'editNode',
      modalEditNodeIdx: event.idx,
      modalEditNodeName: this.state.content.nodes[event.idx].name,
      modalEditNodeDescription: this.state.content.nodes[event.idx].description
    });
      this.resetHightlight();
    this.openModal();
  };

  editNodeProper = event => {
    const content = this.state.content;
    content.nodes[this.state.modalEditNodeIdx].name = this.state.modalEditNodeName;
    content.nodes[this.state.modalEditNodeIdx].description = this.state.modalEditNodeDescription;

    this.setState({
        content,
        lastEditNodeIndeces: [ this.state.modalEditNodeIdx ]
    });
    this.closeModal();
  };

  deleteNodeProper = event => {
    const idx = this.state.modalEditNodeIdx;
    const content = this.state.content;
    for (let i = 0; i < content.links.length; i++) {
      if (content.links[i].source === idx || content.links[i].target === idx) {
        content.links.splice(i, 1);
        i--;
        continue;
      }
      if (content.links[i].source > idx) {
        content.links[i].source--;
      }
      if (content.links[i].target > idx) {
        content.links[i].target--;
      }
    }
    content.nodes.splice(idx, 1);
    this.setState({ content });
    this.closeModal();
  };

  balanceLinkWeightsOnRight = event => this.balanceLinkWeights(event, 'right');
  balanceLinkWeightsOnLeft = event => this.balanceLinkWeights(event, 'left');

  balanceLinkWeights = (event, direction) => {
    const idx = this.state.modalEditNodeIdx;
    const content = this.state.content;
    const lastEditLinkIndeces = this.state.lastEditLinkIndeces;
    const sumLeft = _.sum(_.map(_.filter(content.links, l => l.target === idx), l => Number(l.value)));
    const sumRight = _.sum(_.map(_.filter(content.links, l => l.source === idx), l => Number(l.value)));


    if (direction === 'right') {
      _.each(content.links, (l, lidx) => {
        if (l.source !== idx) return;

        const newValue = Number(l.value) * (sumLeft/sumRight);
        if (newValue !== Number(l.value)) {
          l.value = newValue;
          lastEditLinkIndeces.push(lidx);
        }
      });
    } else if (direction === 'left') {
      _.each(content.links, (l, lidx) => {
        if (l.target !== idx) return;

        const newValue = Number(l.value) * (sumRight/sumLeft);
        if (newValue !== Number(l.value)) {
          l.value = newValue;
          lastEditLinkIndeces.push(lidx);
        }
      });
    }

    this.setState({ content, lastEditLinkIndeces });
    this.closeModal();
  }

  editLink = event => {
    // This means that the link the user tried to edit was a virtual link (=created when depth levels are hidden)
    if (event.idx >= this.state.content.links.length) {
      return;
    }
    this.setState({
      modalMode: 'editLink',
      modalEditLinkIdx: event.idx,
      modalEditLinkValue: this.state.content.links[event.idx].value,
      modalEditLinkDescription: this.state.content.links[event.idx].description,
      modalEditLinkColor: this.state.content.links[event.idx].color
    });
      this.resetHightlight();
    this.openModal();
  };

  editLinkProper = event => {
    const content = this.state.content;
    content.links[this.state.modalEditLinkIdx].value = this.state.modalEditLinkValue;
    content.links[this.state.modalEditLinkIdx].description = this.state.modalEditLinkDescription;
    content.links[this.state.modalEditLinkIdx].color = this.state.modalEditLinkColor;
    this.setState({
        content,
        lastEditLinkIndeces: [ this.state.modalEditLinkIdx ]
    });
    this.closeModal();
  };

  deleteLinkProper = event => {
    const content = this.state.content;
    content.links.splice(this.state.modalEditLinkIdx, 1);
    this.setState({ content });
    this.closeModal();
  };

  validateNewLink = (source, target) => {
    // Both must be numbers >= 0 (note that the inputs are strings, not numbers)
    if (!(source >= 0)) return false;
    if (!(target >= 0)) return false;
    // They cannot be the same node
    if (Number(source) === Number(target)) return false;
    return true;
  };

  cancel = event => {
      this.props.history.push("/");
  };

  hideOrShowNode = event => {
    const hiddenNodes = this.state.hiddenNodes;
    const tmp = hiddenNodes.indexOf(event.idx);
    if (tmp === -1) {
      // Hide node
      hiddenNodes.push(event.idx);
    } else {
      // Show node
      hiddenNodes.splice(tmp, 1);
    }
    this.setState({ hiddenNodes });
      this.resetHightlight();
  };

  autoColorAll = event => this.autoColorByNode(false);
  autoColorGrey = event => this.autoColorByNode(true);

  autoColorByNode(onlyWithoutColor) {
    const content = this.state.content;
    const colorByNode = {};
    let colorIdx = 0;
    _.each(content.nodes, (n, idx) => {
      // Pick first color from links
      let color = _.map(_.filter(content.links, l => (l.source === idx && l.color)), l => l.color)[0];
      if (!color || !onlyWithoutColor) {
        color = defaultColors[colorIdx];
        colorIdx = (colorIdx + 1) % defaultColors.length;
      }
      colorByNode[idx] = color;
    });

    _.each(content.links, l => {
      if (onlyWithoutColor && l.color) return;
      l.color = colorByNode[l.source];
    });
    this.resetHightlight();
    this.setState({ content });
  }

  depthVisibilityButtonVariant(depth) {
    if (this.state.hiddenDepths.indexOf(depth) === -1) {
      return "default";
    } else {
      return "info";
    }
  }

  switchDepthVisibility(depth) {
    var hiddenDepths = this.state.hiddenDepths;
    if (hiddenDepths.indexOf(depth) === -1) {
      hiddenDepths.push(depth);
    } else {
      hiddenDepths = _.without(hiddenDepths, depth);
    }

    hiddenDepths = _.orderBy(hiddenDepths);

    this.setState({ hiddenDepths });
  }

  toggleFullScreenMode(value) {
    const fullScreenMode = value[0] === true;
    const changed = this.state.fullScreenMode !== fullScreenMode;
    this.setState({ fullScreenMode });
    if (changed) {
      setTimeout(d => window.dispatchEvent(new Event('resize')) );
    }
  }

  togglePublished(value) {
    const publish = _.find(value) ? true : false;
    this.setState({ publish });
  }

  getClassNames() {
    var ret = 'Valuetree';
    if (this.state.fullScreenMode) {
      ret += ' FullScreenMode'
    }
    return ret;
  }

  render() {
    return (
      <div className={this.getClassNames()}>
        {this.state.valuetree &&
          <form onSubmit={this.handleSubmit}>
            <FormGroup controlId="treeName">
              <FormControl
                onChange={this.handleChange}
                value={this.state.treeName}
                componentClass="input"
              />
            </FormGroup>
            <SankeyChart
              nodes={this.state.content.nodes}
              links={this.state.content.links}
              hiddenNodes={this.state.hiddenNodes}
              highlightedNodeIndeces={this.state.lastEditNodeIndeces}
              highlightedLinkIndeces={this.state.lastEditLinkIndeces}
              hiddenDepths={this.state.hiddenDepths}
              editNode={this.editNode}
              editLink={this.editLink}
              rightClickNode={this.hideOrShowNode}
              fitHeight={this.state.fullScreenMode}
            />
            <Draggable>
              <div className="ControlBox">
              {this.state.hiddenNodes.length === 0 &&
                <div className="hiddenNodes">
                  <p>You can temporarily hide nodes by right clicking</p>
                </div>
              }
              {this.state.hiddenNodes.length > 0 &&
                <div className="hiddenNodes">
                  <p>You can temporarily hide nodes by right clicking them. Restore nodes by clicking the buttons below.</p>
                  {this.state.hiddenNodes.map(idx => {
                    return <Button 
                      onClick={() => this.hideOrShowNode({ idx })}
                      key={idx}>{this.state.content.nodes[idx].name}</Button>;
                   })}
                </div>
              }
              <FormGroup>
                <span>Hide tree levels from view </span>
                {_.map([7,6,5,4,3,2,1,0], depth =>
                  <Button
                    key={depth}
                    bsStyle={this.depthVisibilityButtonVariant(depth)}
                    onClick={() => this.switchDepthVisibility(depth)}>{depth+1}</Button>
                 )}
              </FormGroup>
              <FormGroup>
                <Button onClick={this.addNode} bsSize="large" bsStyle="success"><b>{"\uFF0B"}</b> Create Node</Button>
                <Button onClick={this.addLink} bsSize="large" bsStyle="success"><b>{"\uFF0B"}</b> Create Link</Button>
              </FormGroup>
              <FormGroup>
                <Button onClick={this.autoColorGrey} bsSize="large" bsStyle="default">Colorize grey links</Button>
                <Button onClick={this.autoColorAll} bsSize="large" bsStyle="default">Re-color entire tree</Button>
              </FormGroup>
              <FormGroup>
                <ControlLabel>Make public?</ControlLabel>
                <ToggleButtonGroup type="checkbox" value={this.state.publish} onChange={this.togglePublished.bind(this)}>
                  <ToggleButton value={true}>Publish</ToggleButton>
                </ToggleButtonGroup>
              </FormGroup>
              <FormGroup>
                <LoaderButton
                  bsStyle="primary"
                  bsSize="large"
                  disabled={!this.validateForm()}
                  type="submit"
                  isLoading={this.state.isLoading}
                  text="Save"
                  loadingText="Saving…"
                />
                <Button bsSize="large" bsStyle="info" onClick={this.prepareDownloadValuetree.bind(this)}>Download SVG</Button>
                <LoaderButton
                  bsStyle="danger"
                  bsSize="large"
                  isLoading={this.state.isDeleting}
                  onClick={this.handleDelete}
                  text="Delete"
                  loadingText="Deleting…"
                />
                <Button bsStyle="info" bsSize="large" onClick={this.cancel}>Cancel</Button>
              </FormGroup>
              <FormGroup>
                <ToggleButtonGroup type="checkbox" onChange={this.toggleFullScreenMode.bind(this)}>
                  <ToggleButton value={true}>Full Screen Mode</ToggleButton>
                </ToggleButtonGroup>
              </FormGroup>
              <FormGroup>
                <Link to={`/view/${this.props.match.params.id}`}>View tree (save first!)</Link>
              </FormGroup>
              </div>
            </Draggable>
          </form>}
        <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={modalStyle}
        >
          {/* Add Node Modal Dialog */}
          {this.state.modalMode === 'addNode' &&
            <div>
              <h3>Add node</h3>
              <FormGroup controlId="modalNewNodeName">
                <ControlLabel>Name</ControlLabel>
                <FormControl
                  onChange={this.handleChange}
                  value={this.state.modalNewNodeName}
                  componentClass="input"
                />
              </FormGroup>
              <FormGroup controlId="modalNewNodeDescription">
                <ControlLabel>Description</ControlLabel>
                <FormControl
                  onChange={this.handleChange}
                  value={this.state.modalNewNodeDescription}
                  componentClass="textarea"
                />
              </FormGroup>
              <Button disabled={this.state.modalNewNodeName.length===0} onClick={this.addNodeProper}>Add</Button>
            </div>
          }
          {/* Add Link Modal Dialog */}
          {this.state.modalMode === 'addLink' &&
            <div>
              <h3>Add link</h3>
              <FormGroup controlId="modalNewLinkSource">
                <ControlLabel>Source node</ControlLabel>
                <FormControl
                  onChange={this.handleChange}
                  value={this.state.modalNewLinkSource}
                  componentClass="select"
                  placeholder="Select">
                  <option value="select">select</option>
                  {this.state.content.nodes.map((e, idx) => {
                      return <option key={idx} value={idx}>{e.name}</option>;
                  })}
                </FormControl>
              </FormGroup>
              <FormGroup controlId="modalNewLinkTarget">
                <ControlLabel>Target node</ControlLabel>
                <FormControl
                  onChange={this.handleChange}
                  value={this.state.modalNewLinkTarget}
                  componentClass="select"
                  placeholder="Select">
                  <option value="select">select</option>
                  {this.state.content.nodes.map((e, idx) => {
                      return <option key={idx} value={idx}>{e.name}</option>;
                  })}
                </FormControl>
              </FormGroup>
              <FormGroup controlId="modalNewLinkValue">
                <ControlLabel>Weight</ControlLabel>
                <FormControl
                  onChange={this.handleChange}
                  value={this.state.modalNewLinkValue}
                  componentClass="input"
                  type="number"
                />
              </FormGroup>
              <FormGroup controlId="modalNewLinkDescription">
                <ControlLabel>Description</ControlLabel>
                <FormControl
                  onChange={this.handleChange}
                  value={this.state.modalNewLinkDescription}
                  componentClass="textarea"
                />
              </FormGroup>
              <FormGroup controlId="modalNewLinkColor">
                <ControlLabel>Link color</ControlLabel>
                <CirclePicker
                  onChange={color => this.setState({modalNewLinkColor: color.hex})}
                  color={this.state.modalNewLinkColor}
                  width={modalStyle.colorPicker.width}
                />
              </FormGroup>
              <Button
                disabled={!(this.state.modalNewLinkValue > 0) ||
                          !this.validateNewLink(this.state.modalNewLinkSource, this.state.modalNewLinkTarget)}
                onClick={this.addLinkProper}>Add</Button>
            </div>
          }
          {/* Edit Node Modal Dialog */}
          {this.state.modalMode === 'editNode' &&
            <div>
              <h3>Edit node</h3>
              <FormGroup controlId="modalEditNodeName">
                <ControlLabel>Name</ControlLabel>
                <FormControl
                  onChange={this.handleChange}
                  value={this.state.modalEditNodeName}
                  componentClass="input"
                />
              </FormGroup>
              <FormGroup controlId="modalEditNodeDescription">
                <ControlLabel>Description</ControlLabel>
                <FormControl
                  onChange={this.handleChange}
                  value={this.state.modalEditNodeDescription}
                  componentClass="textarea"
                />
              </FormGroup>
              <Button disabled={this.state.modalEditNodeName.length===0} onClick={this.editNodeProper}>Update</Button>
              <Button onClick={this.deleteNodeProper} bsStyle="danger">Delete node</Button>
              <p>
                Balance links
                <Button onClick={this.balanceLinkWeightsOnLeft} bsStyle="warning">Left to match right</Button>
                <Button onClick={this.balanceLinkWeightsOnRight} bsStyle="warning">Right to match left</Button>
              </p>
                {this.canInvertSubTree() && this.props.privileges.invertSubTree &&
                  <Button onClick={this.invertSubTreeLinks}>Invert sub tree starting from this node</Button>
                }

            </div>
          }
          {/* Edit Link Modal Dialog */}
          {this.state.modalMode === 'editLink' &&
            <div>
              <h3>Edit link</h3>
              <FormGroup controlId="modalEditLinkValue">
                <ControlLabel>Weight</ControlLabel>
                <FormControl
                  onChange={this.handleChange}
                  value={this.state.modalEditLinkValue}
                  componentClass="input"
                  type="number"
                />
              </FormGroup>
              <FormGroup controlId="modalEditLinkDescription">
                <ControlLabel>Description</ControlLabel>
                <FormControl
                  onChange={this.handleChange}
                  value={this.state.modalEditLinkDescription}
                  componentClass="textarea"
                />
              </FormGroup>
              <FormGroup controlId="modalEditLinkColor">
                <ControlLabel>Link color</ControlLabel>
                <CirclePicker
                  onChange={color => this.setState({modalEditLinkColor: color.hex})}
                  color={this.state.modalEditLinkColor}
                  width={modalStyle.colorPicker.width}
                />
              </FormGroup>
              <Button disabled={!(this.state.modalEditLinkValue > 0)} onClick={this.editLinkProper}>Update</Button>
              <Button onClick={this.deleteLinkProper} bsStyle="danger">Delete link</Button>
            </div>
          }
          <Button onClick={this.closeModal}>Cancel</Button>
            
        </Modal>
      </div>
    );
  }

}

export default Valuetree;